<template>
  <section>
    <b-card>

      <b-row>
        <!----------- Cerveja ----------->
        <b-col>
          <b-form-group
            label="Cerveja"
            label-for="valorCerveja"
          >
            <money
              id="valorCerveja"
              v-model="valorCerveja"
              class="form-control"
              placeholder="Cerveja"
            />

          </b-form-group>
        </b-col>
        <!----------- Destilado ----------->
        <b-col>
          <b-form-group
            label="Destilado"
            label-for="valorDestilado"
          >
            <money
              id="valorDestilado"
              v-model="valorDestilado"
              class="form-control"
              placeholder="Destilado"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!----------- Energetico ----------->
        <b-col>
          <b-form-group
            label="Energetico"
            label-for="valorEnergetico"
          >
            <money
              id="valorEnergetico"
              v-model="valorEnergetico"
              class="form-control"
              placeholder="Energetico"
            />
          </b-form-group>
        </b-col>
        <!----------- Refrigerante ----------->
        <b-col>
          <b-form-group
            label="Refrigerante"
            label-for="valorRefrigerante"
          >
            <money
              id="valorRefrigerante"
              v-model="valorRefrigerante"
              class="form-control"
              placeholder="Refrigerante"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!----------- Suco ----------->
        <b-col>
          <b-form-group
            label="Suco"
            label-for="valorSuco"
          >
            <money
              id="valorSuco"
              v-model="valorSuco"
              class="form-control"
              placeholder="Suco"
            />
          </b-form-group>
        </b-col>
        <!----------- Porção ----------->
        <b-col>
          <b-form-group
            label="Porção"
            label-for="valorPorcao"
          >
            <money
              id="valorPorcao"
              v-model="valorPorcao"
              class="form-control"
              placeholder="Porção"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!----------- Cigarro ----------->
        <b-col>
          <b-form-group
            label="Cigarro"
            label-for="valorCigarro"
          >
            <money
              id="valorCigarro"
              v-model="valorCigarro"
              class="form-control"
              placeholder="Cigarro"
            />
          </b-form-group>
        </b-col>
        <!----------- Comandas ----------->
        <b-col>
          <b-form-group
            label="Comandas"
            label-for="valorComandas"
          >
            <money
              id="valorComandas"
              v-model="valorComandas"
              class="form-control"
              placeholder="Comandas"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Data Criação"
            label-for="nome"
          >
            <flat-pickr
              v-model="multiDate"
              class="form-control"
              placeholder="Selecione a Data"
              :config="{ dateFormat: 'd/m/Y'}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <b-button
            variant="gradient-success"
            block
            class="mt-1"
            @click="SalvarNoticia()"
          >
            Salvar Comanda
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
  },
  data() {
    return {
      url: 'admin/categorias',
      data: {},
      valorCerveja: '',
      valorDestilado: '',
      valorEnergetico: '',
      valorRefrigerante: '',
      valorSuco: '',
      valorPorcao: '',
      valorCigarro: '',
      valorComandas: '',
      multiDate: '',
    }
  },
  methods: {
    BuscarDados(url = this.url, options = this.tableProps) {
      this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    SalvarNoticia() {
      const categoria = {
        id: this.id,
        data: this.multiDate,
        cerveja: this.valorCerveja,
        destilado: this.valorDestilado,
        energetico: this.valorEnergetico,
        refrigerante: this.valorRefrigerante,
        suco: this.valorSuco,
        porcao: this.valorPorcao,
        cigarro: this.valorCigarro,
        comandas: this.valorComandas,
      }
      this.$http.post('painel/comandas', categoria).then(resp => {
        if (resp.data.success) {
          this.Notificacao('success', resp.data.success)
        }
        if (resp.data.error) {
          this.Notificacao('danger', resp.data.error)
        }
        this.$bvModal.hide('PopupComandas')
        this.limpacampos()
      })
      this.limpacampos()
    },
    limpacampos() {
      this.id = null
      this.multiDate = ''
      this.valorCerveja = ''
      this.valorDestilado = ''
      this.valorEnergetico = ''
      this.valorRefrigerante = ''
      this.valorSuco = ''
      this.valorPorcao = ''
      this.valorCigarro = ''
      this.valorComandas = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
